// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import Header from '@/components/Header.vue'
import { customerFactory } from '@/http/customer'
import { masterDataFactory } from '@/http/master-data'
import swal from 'sweetalert'
import Utils from '@/utils/utils'
import router from '@/router/index'
import constants from '@/constants/constants'

@Options({
  components: {
    Header
  },
  data() {
    return {
      orders: [],
      order: [],
      billUserInstallments: [],
      stats: {
        total_bill: 0,
        total_paid: 0
      },
      months: [],
      data_master: {
        school_years: "1",
        months: "1"
      },
      param: {
        status: 0,
        date_from: "",
        date_to: "",
        keyword: "",
        offset: 0,
        limit: 20,
        order_by: "desc",
        order_at: "created_at"
      },
      paramBill: {
        school_year_id: 0
      },
      billCount: 0,
      detailOrder: '',
      detailReady: false,
      constants: constants
    }
  },
  methods: {
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    beautifyDatetime: function(date:any) {
      return Utils.beautifyDatetime(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    invoice: function(order_id:any) {
      if (order_id != undefined) {
        // router.push({path: '/user/invoice', query: {id: order_id}})
        var invoice_path = `/user/invoice/${order_id}`
        router.push({ path: invoice_path })
      }
      return
    },
    orderDetail: function(order_id:any) {
      this.detailReady = false
      Promise.all([
        customerFactory.detailOrder(order_id)
      ]).then(results => {
        this.order = results[0].data.order
        this.billUserInstallments = results[0].data.bill_user_installments
        this.detailReady = true
        this.showModal()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
      return
    },
    loadData: function() {
      Promise.all([
        customerFactory.bill(this.paramBill),
        customerFactory.listOrder(this.param)
      ]).then(results => {
        this.stats = results[0].data.stats
        this.orders = results[1].data.orders
        this.billCount = results[1].data.total_rows
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      Promise.all([
        masterDataFactory.master_datas(this.data_master)
      ]).then(results => {
        this.months = results[0].data.months
        this.schoolYears = results[0].data.school_years
        this.schoolYears.forEach((school_year:any) => {
          if (school_year.status == "Active") {
            this.param.school_year_id = school_year.school_year_id
          }
        })
        this.loadData()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    showModal: function() {
      this.myModal.show()
    },
    hideModal: function() {
      this.myModal.hide()
    }
  },
  async mounted () {
    await this.loadDataMaster()
    var myModal = document.getElementById('orderModal')
    if (myModal != null) {
      this.myModal = new bootstrap.Modal(myModal)
    }
  }
})

export default class UserPayment extends Vue {}
